import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Select, { components, MenuProps } from "react-select";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import moment from "moment";
import { debounce } from "lodash";
import Emitter from "../../utility/emitter";
import TransferInRequestService from "../../services/transfer-in-request.service";
import TransferOutRequestService from "../../services/transfer-out-request.service";
import SearchService from "../../services/search.service";
import DrugRefillsHistoryDataGrid from "../DrugRefillsHistoryDataGrid";
import EditPatientModal from "../EditPatientModal";
import PrescriberModal from "../EditPrescriberModal";
import CsCheckpointModal from "../CsCheckpointModal";
import Swal from "sweetalert2";
import helpers from "../../utility/helpers";
import axios from "axios";
import http from "../../utility/http";
import AllActiveRxForm from "./AllActiveRxForm";
import { useRecoilState } from "recoil";
import { firstDoseState } from "../../atoms/firstDoes";
// import FormControl from "../FormControl";

const mockData = {
  PrescribedDrug: {
    "label": "Allopurinol 100 MG TABS (00378013701)",
    "controlledSubstanceCode": "",
    "manufacturer": "MYLAN",
    "isTransferable": true,
    "name": "Allopurinol 100 MG TABS",
    "genericProductIdentifier": "68000010000305",
    "ndcNumber": "00378013701",
    "id": "9999",
    "objectID": "9999",
    "_highlightResult": {
      "label": {
        "value": "<em>A</em>llopurinol 100 MG TABS (00378013701)",
        "matchLevel": "full",
        "fullyHighlighted": false,
        "matchedWords": [
          "a"
        ]
      },
      "controlledSubstanceCode": {
        "value": "",
        "matchLevel": "none",
        "matchedWords": []
      },
      "manufacturer": {
        "value": "MYLAN",
        "matchLevel": "none",
        "matchedWords": []
      },
      "name": {
        "value": "<em>A</em>llopurinol 100 MG TABS",
        "matchLevel": "full",
        "fullyHighlighted": false,
        "matchedWords": [
          "a"
        ]
      },
      "genericProductIdentifier": {
        "value": "68000010000305",
        "matchLevel": "none",
        "matchedWords": []
      },
      "ndcNumber": {
        "value": "00378013701",
        "matchLevel": "none",
        "matchedWords": []
      },
      "id": {
        "value": "9999",
        "matchLevel": "none",
        "matchedWords": []
      }
    }
  }
}
const TransferRequestModal = (props) => {

  const [isManualQtyRemaining, setIsManualQtyRemaining] = useState(false);
  const [firstDose, setFirstDose] = useRecoilState(firstDoseState);
  const [isUploading, setIsUplaoding] = useState(false);
  const [key, setKey] = useState(false);
  const uploadHandler = (event, cb) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    setIsUplaoding(true);
    axios
      .post(`https://portal-svc--pharmacy-b-7j5ay3qlhq-uc.a.run.app/v1/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle success, e.g., show a success message to the user
        setKey(response?.data?.key);
        cb && cb(response?.data?.key);
        setIsUplaoding(false);
      })
      .catch((error) => {
        setIsUplaoding(false);
      });
  };

  const fieldsSchema = {
    patient: { isRequired: true },
    rxNumber: { isRequired: true },
    pharmacyBranch: { isRequired: true },
    prescriber: { isRequired: props.requesttype === 1 },
    rxIssueDate: { isRequired: props.requesttype === 1 },
    prescriberSupervisor: { isRequired: false },
    prescribedDrug: { isRequired: true },
    dispenseAsWrittenType: { isRequired: props.requesttype === 1 },
    expirationDate: { isRequired: props.requesttype === 1 },
    qtyPrescribed: { isRequired: props.requesttype === 1 },
    originalRefills: { isRequired: false },
    refillsRemaining: { isRequired: false },
    qtyRemaining: { isRequired: props.requesttype === 1 },
    uploadImg: { isRequired: false },
    notes: { isRequired: false },
    directions: { isRequired: props.requesttype === 1, cssClass: " w-100" },
    firstFillDate: { isRequired: props.requesttype === 1 },
    lastFillDate: { isRequired: props.requesttype === 1 },
    refillsHistory: { isRequired: false, cssClass: " w-100" },
    neverFilled: { isRequired: false, cssClass: " w-100" },
  };

  const lookups = useSelector((state) => state.lookups);

  const [patientModalShow, setPatientModalShow] = useState(false);
  const [prescriberModalShow, setPrescriberModalShow] = useState(0);
  const [csCheckpointModalShow, setCsCheckpointModalShow] = useState(false);
  const [csCheckpointResult, setCsCheckpointResult] = useState({});

  const [patient, setPatient] = useState(null);
  const [dispenseAsWrittenType, setDispenseAsWrittenType] = useState(
    lookups?.dispenseAsWrittenTypes && lookups?.dispenseAsWrittenTypes[0]
  );

  const [rxNumber, setRxNumber] = useState("");

  const [pharmacyBranch, setPharmacyBranch] = useState(null);

  const [expirationDate, setExpirationDate] = useState("");
  const [neverFilled, setNeverFilled] = useState(1);
  const [prescriber, setPrescriber] = useState(null);
  const [prescriberSupervisor, setPrescriberSupervisor] = useState(null);
  const [firstFillDate, setFirstFillDate] = useState("");
  const [lastFillDate, setLastFillDate] = useState("");
  const [prescribedDrug, setPrescribedDrug] = useState(null);
  const [dispensedDrug, setDispensedDrug] = useState(null);

  const [qtyPrescribed, setQtyPrescribed] = useState(0);
  const [originalRefills, setOriginalRefills] = useState(0);
  const [refillsRemaining, setRefillsRemaining] = useState(0);
  const [qtyRemaining, setQtyRemaining] = useState(0);
  const [uploadImg, setUploadImg] = useState("");
  const [directions, setDirections] = useState("");
  const [notes, setNotes] = useState("");
  const [refillsHistory, setRefillsHistory] = useState([]);
  const [rxIssueDate, setRxIssueDate] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [showMoreFields, setShowMoreFields] = useState(props.requesttype === 1);

  const [queryResult, setQueryResult] = useState("");
  const getQueryResult = async () => {
    const result = await axios.post(`https://open-api-810950398464.us-central1.run.app/v1/product-locator-query`, {
      pharmacyNCPDC: pharmacyBranch.ncpdp,
      NDCNumber: prescribedDrug.ndcNumber,
      quantity: qtyPrescribed,
    });

    if (result?.data?.data?.success) {
      const resultData = result?.data?.data?.result;
      if (resultData.quantity) {
        if (resultData.quantity === "InsufficientQuantity") {
          setQueryResult(`Insufficient Quantity: ${resultData?.aviableQuantity}`);
        } else {
          setQueryResult(resultData.quantity);
        }
      } else {
        setQueryResult("Cannot access pharmacy data");
      }
    } else {
      setQueryResult("Cannot access pharmacy data");
    }
  }
  useEffect(() => {
    console.log("prescribedDrug", prescribedDrug);
    if (pharmacyBranch && prescribedDrug && qtyPrescribed) {
      getQueryResult();
    }
  }, [pharmacyBranch, prescribedDrug, qtyPrescribed]);


  const toggleShowMoreFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  const loadOptions = debounce((category, inputValue, callback) => {
    SearchService.Search(category, inputValue).then((data) =>
      callback(data?.data)
    );
  }, 300);

  const formatOptionLabel = ({ label, id, phone }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    let highlighted;
    if (label.toLowerCase().includes(inputValue.toLowerCase())) {
      highlighted = `${label}-${phone}`
        .toLowerCase()
        .replaceAll(inputValue.toLowerCase(), `<b>${inputValue}</b>`);

      return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
    }
    return label;
  };

  const [prescriptions, setPrescriptions] = useState([]);
  const [prescriptionsErrors, setPrescriptionsErrors] = useState([]);
  const handleSubmit = async (action) => {
    console.log("props.request", props.request)
    console.log("prescriber", prescriber)
    console.log("prescriberSupervisor", prescriberSupervisor)
    console.log("props.requesttype", props.requesttype)
    // props.requesttype === 1 out
    // props.requesttype === 0 in
    console.log("userData", userData?.fullName)
    console.log("isDemo", isDemo)
    if (props?.request?.RxNumber === "ALL ACTIVE RXs") {
      if (prescriptionCheckErrors()) {
        // Swal.fire({
        //   title: "",
        //   text: "Some fields is missing",
        //   icon: "error",
        // });
        return;
      }
      prescriptions.forEach(async (prescription) => {
        const requestMethod = TransferOutRequestService.Create;
        const result = await requestMethod({
          transferRequestId: props.request?.RequestId,
          rxNumber: prescription.rxNumber,
          fromPharmacyBranchId: null,
          toPharmacyBranchId: pharmacyBranch?.id,
          expirationDate:
            prescription.expirationDate === ""
              ? null
              : prescription.expirationDate,
          dispenseAsWrittenTypeId: dispenseAsWrittenType?.id,
          prescribedDrugId: prescription.prescribedDrug?.id,
          prescribedDrugName: prescription.prescribedDrug?.label,
          dispensedDrugId: prescription.dispensedDrug?.id,
          dispensedDrugName: prescription.dispensedDrug?.label,
          qtyPrescribed: prescription.qtyPrescribed,
          originalRefills: prescription.originalRefills,
          refillsRemaining: prescription.refillsRemaining,
          qtyRemaining: prescription.qtyRemaining,
          rxIssueDate:
            prescription.rxIssueDate === "" ? null : prescription.rxIssueDate,
          patientId: patient?.id,
          prescriberId: prescription.prescriber?.id,
          prescriberSupervisorId: prescription.prescriberSupervisor?.id,
          prescriber: prescription.prescriber,
          prescriberSupervisor: prescription.prescriberSupervisor,
          directions: prescription.directions,
          notes: prescription.notes,
          neverFilled: prescription.neverFilled,
          firstFillDate:
            prescription.neverFilled || prescription.firstFillDate === ""
              ? null
              : prescription.firstFillDate,
          lastFillDate:
            prescription.neverFilled || prescription.lastFillDate === ""
              ? null
              : prescription.lastFillDate,
          drugRefillsHistory: prescription.neverFilled
            ? []
            : prescription.refillsHistory?.map((item) => ({
              Date: !item.Date ? null : item.Date,
              DrugId: !item.DrugId ? null : item.DrugId,
              DrugName: !item.DrugName ? null : item.DrugName,
              Qty: item.Qty,
            })),
          fileKey: prescription.fileKey,
        });
        props.cb && props.cb("", 1, 50);
        if (
          result.status !== 500 /*&& (props.request === null || action === 1)*/
        ) {
          props.onHide(true);
          setPrescriptions([]);
          setPrescriptionsErrors([]);
        }
      });
    } else {
      if (props.customSubmit) {
        props.customSubmit();
      } else {
        if (!checkErrors()) {
          return;
        }
        if (
          csCheckpointResult.answer1 === true &&
          refillsHistory.length === 0
        ) {
          Swal.fire({
            title: "",
            text: "Refill History is required",
            icon: "error",
          });
          return;
        }
        const requestMethod = action
          ? TransferOutRequestService.FinishReview
          : props.requesttype === 1
            ? props.request?.RequestId
              ? TransferOutRequestService.Update
              : TransferOutRequestService.Create
            : props.request?.RequestId
              ? TransferInRequestService.Update
              : TransferInRequestService.Create;
        const result = await requestMethod({
          transferRequestId: props.request?.RequestId,
          rxNumber: rxNumber,
          fromPharmacyBranchId:
            props.requesttype === 1 ? null : pharmacyBranch?.id,
          toPharmacyBranchId:
            props.requesttype === 1 ? pharmacyBranch?.id : null,
          expirationDate: expirationDate === "" ? null : expirationDate,
          dispenseAsWrittenTypeId: dispenseAsWrittenType?.id,
          prescribedDrugId: prescribedDrug?.id,
          prescribedDrugName: prescribedDrug?.label,
          dispensedDrugId: dispensedDrug?.id,
          dispensedDrugName: dispensedDrug?.label,
          qtyPrescribed: qtyPrescribed,
          originalRefills: originalRefills,
          refillsRemaining: refillsRemaining,
          qtyRemaining: qtyRemaining,
          rxIssueDate: rxIssueDate === "" ? null : rxIssueDate,
          patientId: patient?.id,
          prescriberId: prescriber?.id,
          prescriberSupervisorId: prescriberSupervisor?.id,
          prescriber: prescriber,
          prescriberSupervisor: prescriberSupervisor,
          directions: directions,
          notes: notes,
          neverFilled: neverFilled,
          firstFillDate:
            neverFilled || firstFillDate === "" ? null : firstFillDate,
          lastFillDate:
            neverFilled || lastFillDate === "" ? null : lastFillDate,
          drugRefillsHistory: neverFilled
            ? []
            : refillsHistory?.map((item) => ({
              Date: !item.Date ? null : item.Date,
              DrugId: !item.DrugId ? null : item.DrugId,
              DrugName: !item.DrugName ? null : item.DrugName,
              Qty: item.Qty,
            })),
          fileKey: key,
          trxTraceId: props.request?.trxTraceId,
          fromPharmacyPharmacistName: props.requesttype === 1 ? userData?.fullName : null,
          toPharmacyPharmacistName: props.requesttype === 1 ? null : userData?.fullName,
        });
        props.cb && props.cb("", 1, 50);
        if (!result.error /*&& (props.request === null || action === 1)*/) {
          props.onHide(true);
        }
      }
    }
  };

  const prescriptionCheckErrors = () => {
    let errors = { ...prescriptionsErrors };
    let counts = {};
    prescriptions.forEach((prescription) => {
      console.log(prescription);
      let count = 0;
      console.log("prescription?.refillsHistory", prescription?.refillsHistory);
      if (
        prescription?.neverFilled === 0 &&
        prescription?.refillsHistory?.length === 0
      ) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            refillsHistory: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.refillsHistory;
      }
      if (!prescription.expirationDate) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            expirationDate: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.expirationDate;
      }

      if (!prescription.rxIssueDate) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            rxIssueDate: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.rxIssueDate;
      }
      if (!prescription.directions?.length) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            directions: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.directions;
      }

      if (!prescription.qtyPrescribed) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            qtyPrescribed: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.qtyPrescribed;
      }

      if (!prescription.refillsRemaining) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            refillsRemaining: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.refillsRemaining;
      }

      if (
        !prescription.dispenseAsWrittenType?.id &&
        !prescription.dispenseAsWrittenType?.label
      ) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            dispenseAsWrittenType: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.dispenseAsWrittenType;
      }

      if (
        !prescription.prescribedDrug?.id &&
        !prescription.prescribedDrug?.label
      ) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            prescribedDrug: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.prescribedDrug;
      }

      if (!prescription.prescriber?.id && !prescription.prescriber?.label) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            prescriber: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.prescriber;
      }

      if (!prescription.rxIssueDate) {
        errors = {
          ...errors,
          [prescription.index]: {
            ...errors[prescription.index],
            rxIssueDate: "Field is required",
          },
        };
        count++;
      } else {
        delete errors[prescription.index]?.rxIssueDate;
      }

      // if (
      //   !prescription.prescriberSupervisor?.id &&
      //   !prescription.prescriberSupervisor?.label
      // ) {
      //   errors = {
      //     ...errors,
      //     [prescription.index]: {
      //       ...errors[prescription.index],
      //       prescriberSupervisor: "Field is required",
      //     },
      //   };
      //   count++;
      // } else {
      //   delete errors[prescription.index]?.prescriberSupervisor;
      // }

      counts = {
        ...counts,
        [prescription.index]: count,
      };
    });
    console.log("errors", errors);
    setPrescriptionsErrors(errors);
    ///if counts.count === 0 return true else return false
    let isError = false;
    Object.values(counts).forEach((count) => {
      if (count > 0) {
        isError = true;
      }
    });
    return isError;

    // if (
    //   props.requesttype === 1 &&
    //   !lastFillDate &&
    //   (neverFilled === 0 || csCheckpointResult.answer1 === true)
    // ) {
    //   errors.lastFillDate = "Field is required";
    // }
  };

  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;
  useEffect(() => {
    if (props.requesttype === 1 && rxNumber == "125125") {
      console.log("userData", userData);

      setPrescribedDrug(mockData.PrescribedDrug);
      setDispensedDrug(mockData.PrescribedDrug);

      setPrescriber({
        id: "749577",
        label: "Joseph Acka",
      });
      setPrescriberSupervisor({
        id: "749577",
        label: "Joseph Acka",
      });
      setDispenseAsWrittenType({
        id: "2",
        label: "Substitution Not Allowed by Prescriber",
      });

      if (userData.pharmacyId === 17102) {
        setPatient({
          id: 5915385,
          label: "Pharmacy B",
        });
        // setPharmacyBranch({
        //   id: 81675,
        //   label: "Fatta Drive Pharmacy (2800 W Main St Ste H, League City, Texas)",

        //   PharmacyBranchStateName: "Texas",
        //   PharmacyBranchCityName: "League City",
        //   PharmacyBranchAddress: "2800 W Main St Ste H",
        // });

      } else {
        // setPharmacyBranch({
        //   id: 19554,
        //   label: "The Bleeding And Clotting Disorders Institute (427 W Northmoor Rd, Peoria, Illinois)",

        //   PharmacyBranchStateName: "Illinois",
        //   PharmacyBranchCityName: "Peoria",
        //   PharmacyBranchAddress: "427 W Northmoor Rd",
        // });
        setPatient({
          id: 5915637,
          label: "Pharmacy A",
        });
      }
      const rxIssueDateValue = moment(new Date());

      setRxIssueDate(rxIssueDateValue.format("yyyy-MM-DD"));
      setExpirationDate(
        rxIssueDateValue
          .add(
            364,
            "days"
          )
          .format("yyyy-MM-DD")
      );

      // setFirstFillDate(props.request.FirstFillDate);
      // setLastFillDate(props.request.LastFillDate);
      setQtyPrescribed(2);
      setOriginalRefills(2);
      setRefillsRemaining(1);
      setQtyRemaining(1);


      // setRefillsHistory(props.request.DrugRefillsHistory);
      setDirections("Directions");
      setNotes("Notes");

      // setNeverFilled(props.request.NeverFilled || 1);
    }
  }, [rxNumber]);
  const handleOnShowModal = () => {
    setShowMoreFields(props.requesttype === 1);
    setFormErrors({});
    if (props.request === null) {
      setRxNumber("");
      setRxIssueDate("");
      setPatient(null);
      setPrescriber(null);
      setPrescriberSupervisor(null);
      setPharmacyBranch(null);
      setPrescribedDrug(null);
      setDispenseAsWrittenType(
        props.requesttype === 1
          ? lookups?.dispenseAsWrittenTypes &&
          lookups?.dispenseAsWrittenTypes[0]
          : null
      );
      setDispensedDrug(null);
      setExpirationDate("");

      setFirstFillDate("");
      setLastFillDate("");

      setQtyPrescribed(0);
      setOriginalRefills(0);
      setRefillsRemaining(0);
      setQtyRemaining(0);

      setRefillsHistory([]);
      setDirections("");
      setNotes("");
      setNeverFilled(1);
    } else {

      console.log("props.request", props.request.RxNumber);
      if (props?.request?.RxNumber !== "125125") {
        setRxNumber(props.request.RxNumber);
        setRxIssueDate(props.request.RxIssueDate);
        console.log("isDemo", isDemo);
        setPatient({
          id: props.request.PatientId,
          label: props.request.PatientName,
        });
        setPharmacyBranch({
          id:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchId
              : props.request.FromPharmacyBranchId,
          label:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchName
              : props.request.FromPharmacyBranchName,

          PharmacyBranchStateName:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchStateName
              : props.request.FromPharmacyBranchStateName,
          PharmacyBranchCityName:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchCityName
              : props.request.FromPharmacyBranchCityName,
          PharmacyBranchAddress:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchAddress
              : props.request.FromPharmacyBranchAddress,
        });
        setPrescriber({
          id: props.request.PrescriberId,
          label: props.request.PrescriberName,
        });
        setPrescriberSupervisor({
          id: props.request.PrescriberSupervisorId,
          label: props.request.PrescriberSupervisorName,
        });
        setPrescribedDrug({
          id: props.request.PrescribedDrugId,
          label: props.request.PrescribedDrugName,
        });
        setDispensedDrug({
          id: props.request.PrescribedDrugId,
          label: props.request.PrescribedDrugName,
        });
        setExpirationDate(props.request.ExpirationDate);
        setFirstFillDate(props.request.FirstFillDate);
        setLastFillDate(props.request.LastFillDate);
        setDispensedDrug({
          id: props.request.DispensedDrugId,
          label: props.request.DispensedDrugName,
        });
        setQtyPrescribed(props.request.QtyPrescribed);
        setOriginalRefills(props.request.OriginalRefills);
        setRefillsRemaining(props.request.RefillsRemaining);
        setQtyRemaining(props.request.QtyRemaining);
        setDispenseAsWrittenType({
          id: props.request.DispenseAsWrittenTypeId,
          label: props.request.DispenseAsWrittenTypeName,
        });

        setRefillsHistory(props.request.DrugRefillsHistory);
        setDirections(props.request.Directions);
        setNotes(props.request.Notes);

        setNeverFilled(props.request.NeverFilled || 1);
      } else {
        setRxNumber(props.request.RxNumber);
        setRxIssueDate(props.request.RxIssueDate);
        setPatient({
          id: props.request.PatientId,
          label: props.request.PatientName,
        });
        setPrescribedDrug(mockData.PrescribedDrug);
        setDispensedDrug(mockData.PrescribedDrug);

        setPrescriber({
          id: "749577",
          label: "Joseph Acka",
        });
        setPrescriberSupervisor({
          id: "749577",
          label: "Joseph Acka",
        });
        setDispenseAsWrittenType({
          id: "2",
          label: "Substitution Not Allowed by Prescriber",
        });


        setPharmacyBranch({
          id:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchId
              : props.request.FromPharmacyBranchId,
          label:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchName
              : props.request.FromPharmacyBranchName,

          PharmacyBranchStateName:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchStateName
              : props.request.FromPharmacyBranchStateName,
          PharmacyBranchCityName:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchCityName
              : props.request.FromPharmacyBranchCityName,
          PharmacyBranchAddress:
            props.requesttype === 1
              ? props.request.ToPharmacyBranchAddress
              : props.request.FromPharmacyBranchAddress,
        });

        const rxIssueDateValue = moment(new Date());

        setRxIssueDate(rxIssueDateValue.format("yyyy-MM-DD"));
        setExpirationDate(
          rxIssueDateValue
            .add(
              364,
              "days"
            )
            .format("yyyy-MM-DD")
        );

        setFirstFillDate(props.request.FirstFillDate);
        setLastFillDate(props.request.LastFillDate);
        setQtyPrescribed(2);
        setOriginalRefills(2);
        setRefillsRemaining(1);
        setQtyRemaining(1);


        setRefillsHistory(props.request.DrugRefillsHistory);
        setDirections("Directions");
        setNotes("Notes");

        setNeverFilled(props.request.NeverFilled || 1);
      }

    }
  };

  const checkErrors = () => {
    const errors = {};
    if (props.requesttype === 1 && !rxNumber) {
      errors.rxNumber = "Field is required";
    }

    if (
      props.requesttype === 0 &&
      !rxNumber &&
      !prescribedDrug?.id &&
      !prescribedDrug?.label
    ) {
      errors.rxNumber =
        "Either this field or Prescribed Drug field is required";
      errors.prescribedDrug =
        "Either this field or Rx Number field is required";
    }

    if (props.requesttype === 1 && !rxIssueDate) {
      errors.rxIssueDate = "Field is required";
    }
    console.log("patient", patient);
    if (!patient?.id) {
      errors.patient = "Field is required";
    }

    if (props.requesttype === 1 && !prescriber?.id) {
      errors.prescriber = "Field is required";
    }

    if (!pharmacyBranch?.id) {
      errors.pharmacyBranch = "Field is required";
    }

    if (props.requesttype === 1 && !dispenseAsWrittenType?.id) {
      errors.dispenseAsWrittenType = "Field is required";
    }

    if (
      props.requesttype === 1 &&
      !prescribedDrug?.id &&
      !prescribedDrug?.label
    ) {
      errors.prescribedDrug = "Field is required";
    }

    if (
      false &&
      props.requesttype === 1 &&
      !dispensedDrug?.id &&
      !dispensedDrug?.label
    ) {
      errors.dispensedDrug = "Field is required";
    }

    if (props.requesttype === 1 && !expirationDate) {
      errors.expirationDate = "Field is required";
    }

    if (
      props.requesttype === 1 &&
      (qtyPrescribed === null ||
        qtyPrescribed === "" ||
        qtyPrescribed === 0 ||
        qtyPrescribed === "0")
    ) {
      errors.qtyPrescribed = "Field is required";
    }

    if (
      props.requesttype === 1 &&
      (qtyRemaining === null ||
        qtyRemaining === "" ||
        qtyRemaining === 0 ||
        qtyRemaining === "0")
    ) {
      errors.qtyRemaining = "Field is required";
    }

    if (
      props.requesttype === 1 &&
      !firstFillDate &&
      (neverFilled === 0 || csCheckpointResult.answer1 === true)
    ) {
      errors.firstFillDate = "Field is required";
    }

    if (
      props.requesttype === 1 &&
      !lastFillDate &&
      (neverFilled === 0 || csCheckpointResult.answer1 === true)
    ) {
      errors.lastFillDate = "Field is required";
    }

    if (props.requesttype === 1 && !directions) {
      errors.directions = "Field is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getStatusStyle = () => {
    switch (props?.request?.StatusId) {
      case 1:
      case 2:
        return "warning";
      case 3:
        return "success";
      case 4:
      case 5:
        return "danger";
      default:
        return "";
    }
  };

  const getStatusIcon = () => {
    switch (props?.request?.StatusId) {
      case 1:
        return "fa-solid fa-circle-exclamation";
      case 2:
        return "fa-solid fa-circle-exclamation";
      default:
        return "";
    }
  };

  const getStatusMessage = () => {
    switch (props?.request?.StatusId) {
      case 1:
        return (
          <span className="w-100">Transfer request is pending acceptance</span>
        );
      case 2:
        return (
          <span className="w-100">Transfer request is pending review</span>
        );
      default:
        return null;
    }
  };

  const getCustomClasses = (fieldName) => {
    return !showMoreFields && !fieldsSchema[fieldName].isRequired
      ? "d-none"
      : `form-group ${!!formErrors[fieldName] ? " invalid" : ""}${fieldsSchema[fieldName].cssClass
        ? fieldsSchema[fieldName].cssClass
        : ""
      }${!showMoreFields ? " w-100" : ""}`;
  };

  const PrescriberMenu = (props) => {
    return (
      <div className="position-relative">
        <div className="position-absolute container text-center react-select-menu-header">
          <div className="row">
            <div className="col">Name</div>
            <div className="col">NPI</div>
            <div className="col">Address</div>
          </div>
        </div>
        <components.Menu {...props} className="pt-4">
          {props.children}
        </components.Menu>
      </div>
    );
  };

  const PatientMenu = (props) => {
    return (
      <div className="position-relative">
        <div className="position-absolute container text-center react-select-menu-header">
          <div className="row">
            <div className="col">Name</div>
            <div className="col">Mobile</div>
            <div className="col">DOB</div>
          </div>
        </div>
        <components.Menu {...props} className="pt-4">
          {props.children}
        </components.Menu>
      </div>
    );
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {props.data.label}
      </components.SingleValue>
    );
  };

  const [isChecked, setIsChecked] = useState(false);

  console.log("userData", userData);
  const [isDemo, setIsDemo] = useState(false);
  const [isPrepareDemoData, setIsPrepareDemoData] = useState(false);
  useEffect(() => {
    if (userData?.email === "demo@transfermyrx.com") {
      setIsDemo(true);
      setIsPrepareDemoData(true);
      setTimeout(() => {
        setIsPrepareDemoData(false);
        setPatient({ id: "5915706" });
        setPharmacyBranch({
          id: 82707,
          label: "ABCD Pharmacy (1 Main st, Texas, Texas)",
        });
      }, 3000);
    }
  }, [userData, isDemo]);
  return (
    <>
      <Modal
        onHide={() => {
          setIsDemo(false)
          setIsPrepareDemoData(false)
          props?.onHide()
        }}
        show={props.show}
        aria-labelledby="transfer-request-modal-title"
        centered
        backdrop="static"
        keyboard={false}
        scrollable
        fullscreen="lg-down"
        dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
        onShow={handleOnShowModal}
      >

        {isPrepareDemoData && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="mt-3">Preparing Demo Data...</div>
          </div>
        )}
        <Modal.Header
          closeButton
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Modal.Title id="transfer-request-modal-title">
            {props?.title
              ? props.title
              : props.request?.RequestId !== undefined
                ? `Edit ${props.requesttype === 1 ? "Transfer Out" : "Transfer In"
                } Request #${props.request?.RequestId}`
                : `Request A New ${props.requesttype === 1 ? "Transfer Out" : "Transfer In"
                }`}
          </Modal.Title>
        </Modal.Header>

        {props?.request?.RequestId !== undefined &&
          props?.request?.StatusId !== 3 &&
          props?.request?.StatusId !== 4 &&
          props?.request?.StatusId !== 5 ? (
          <div
            className={`alert alert-${getStatusStyle()} d-flex align-items-center`}
            role="alert"
          >
            <i className={`${getStatusIcon()} me-3`}></i>
            {getStatusMessage()}
            {(props.requesttype === 1 && props?.request?.StatusId === 2) ||
              (props.requesttype !== 1 && props?.request?.StatusId === 1) ? (
              <button
                className="btn btn-danger"
                onClick={() => props.handleRejectTransferRequestModalShow(true)}
              >
                Reject
              </button>
            ) : null}
            {(props.requesttype === 1 && props?.request?.StatusId === 1) ||
              (props.requesttype !== 1 && props?.request?.StatusId === 2) ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => props.handleCancelTransferRequestModalShow(true)}
                style={{ minWidth: 180 }}
              >
                Transfer Withdrawal
              </button>
            ) : null}
          </div>
        ) : null}

        <Modal.Body>
          <form>
            {props?.request?.RxNumber === "ALL ACTIVE RXs" ? (
              <AllActiveRxForm
                setPatientModalShow={setPatientModalShow}
                formatOptionLabel={formatOptionLabel}
                setPrescriptions={setPrescriptions}
                prescriptions={prescriptions}
                prescriptionsErrors={prescriptionsErrors}
                getCustomClasses={getCustomClasses}
                PatientMenu={PatientMenu}
                SingleValue={SingleValue}
                setPatient={setPatient}
                loadOptions={loadOptions}
                patient={patient}
                setPharmacyBranch={setPharmacyBranch}
                pharmacyBranch={pharmacyBranch}
                formErrors={formErrors}
                requesttype={props.requesttype}
                setCsCheckpointModalShow={setCsCheckpointModalShow}
                setCsCheckpointResult={setCsCheckpointResult}
                setPrescriberModalShow={setPrescriberModalShow}
                PrescriberMenu={PrescriberMenu}
                lookups={lookups}
                uploadHandler={uploadHandler}
                request={props.request}
              />
            ) : (
              <div className="container">
                <div className={getCustomClasses("patient")}>
                  <label htmlFor="patient-ddl" className="form-label">
                    Patient
                  </label>
                  <i className="fa fa-asterisk"></i>
                  <div className="d-flex">
                    {isDemo ? (<>
                      <div>Demo Green</div>
                    </>) : <>
                      {props?.viewMode ? (
                        <>{props?.request?.patientData?.patientFullName}</>
                      ) : (
                        <AsyncSelect
                          id="patient-ddl"
                          test-id="patient-ddl"
                          className="me-3 w-100"
                          classNamePrefix="react-select"
                          menuPosition="fixed"
                          components={{ Menu: PatientMenu, SingleValue }}
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          value={patient}
                          loadOptions={(inputValue, callback) =>
                            loadOptions("patients", inputValue, callback)
                          }
                          formatOptionLabel={(dataRow, { inputValue }) => {
                            return (
                              <div className="row">
                                <div className="col">{dataRow.label}</div>
                                <div className="col">{dataRow.mobile}</div>
                                <div className="col">{dataRow.dateOfBirth}</div>
                              </div>
                            );
                          }}
                          onChange={(item) => {
                            setPatient(item);
                            if (item?.PreferredPharmacyBranchId) {
                              setPharmacyBranch({
                                id: item.PreferredPharmacyBranchId,
                                label: item.PreferredPharmacyBranch,
                              });
                            }
                          }}
                        />
                      )}
                      {!props.viewMode && (
                        <button
                          className="btn btn-outline-primary rounded-circle"
                          type="button"
                          title="Add New Patient"
                          test-id="add-new-patient"
                          onClick={() => setPatientModalShow(true)}
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      )}
                    </>}
                  </div>

                  <small className="text-danger">{formErrors.patient}</small>
                </div>
                {props?.requesttype == 0 && (
                  <div
                    style={{
                      marginBottom: 20,
                      marginLeft: 10,
                    }}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={isChecked}
                        onChange={() => {
                          setIsChecked(!isChecked);
                          if (!isChecked) {
                            setRxNumber("ALL ACTIVE RXs");
                          } else {
                            setRxNumber("");
                          }
                        }}
                        id="defaultCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        I want all active rx for this patient
                      </label>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={getCustomClasses("rxNumber")}>
                    <label htmlFor="rx-number-input" className="form-label">
                      Rx Number
                    </label>
                    {props?.viewMode ? (
                      <div>{props?.request?.rxNumber}</div>
                    ) : (
                      <>
                        {props.requesttype === 1 ? (
                          <i className="fa fa-asterisk"></i>
                        ) : (
                          <>
                            <i className="fa fa-asterisk"></i>
                            {/* <span className="form-field-optional-mark">
                        (Either this field or Prescribed Drug field is required)
                      </span> */}
                          </>
                        )}
                        <input
                          test-id="rx-number-input"
                          id="rx-number-input"
                          value={rxNumber || ""}
                          onChange={(event) => setRxNumber(event?.target.value)}
                          type="text"
                          className="form-control"
                          disabled={isChecked}
                        />
                        {formErrors.rxNumber && (
                          <div className="invalid-feedback">
                            {formErrors.rxNumber}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {props.requesttype !== 1 && !props.viewMode ? (
                    <div style={{ marginTop: 12 }}>OR</div>
                  ) : (
                    <></>
                  )}
                  <div className={getCustomClasses("prescribedDrug")}>
                    <label htmlFor="prescribed-drug-ddl" className="form-label">
                      Prescribed Drug
                    </label>

                    {props?.viewMode ? (
                      <div>{props?.request?.drugName}</div>
                    ) : (
                      <>
                        {props.requesttype === 1 ? (
                          <i className="fa fa-asterisk"></i>
                        ) : (
                          <>
                            <i className="fa fa-asterisk"></i>
                            {/* <span className="form-field-optional-mark">
                        (Either this field or Rx Number field is required)
                      </span> */}
                          </>
                        )}
                        <AsyncCreatableSelect
                          onInputChange={(item) => { }}
                          isDisabled={isChecked}
                          id="prescribed-drug-ddl"
                          test-id="prescribed-drug-ddl"
                          className="me-3 w-100"
                          classNamePrefix="react-select"
                          menuPosition="fixed"
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          value={prescribedDrug}
                          placeholder="Select Prescribed Drug"
                          onCreateOption={(inputValue) => {
                            setPrescribedDrug({ id: null, label: inputValue });
                            setDispensedDrug({ id: null, label: inputValue });
                          }}
                          onChange={(item) => {
                            if (item?.controlledSubstanceCode === "2") {
                              setPrescribedDrug(null);
                              setDispensedDrug(null);
                              Emitter.emit("SHOW_NOTIFICATION", {
                                message:
                                  "Unable to proceed with the selected prescribed drug!",
                                isError: true,
                              });
                              return;
                            }
                            if (
                              props.requesttype === 1 &&
                              item?.controlledSubstanceCode !== undefined &&
                              item?.controlledSubstanceCode !== ""
                            ) {
                              setCsCheckpointModalShow(true);
                            } else {
                              setCsCheckpointResult({});
                            }
                            console.log("item", item);

                            setPrescribedDrug(item);
                            setDispensedDrug(item);

                            if (!!rxIssueDate) {
                              const rxIssueDateValue = moment(rxIssueDate);
                              setExpirationDate(
                                rxIssueDateValue
                                  .add(
                                    item?.controlledSubstanceCode !==
                                      undefined &&
                                      item?.controlledSubstanceCode !== ""
                                      ? 179
                                      : 364,
                                    "days"
                                  )
                                  .format("yyyy-MM-DD")
                              );
                            }

                            if (
                              !!firstFillDate &&
                              item !== null &&
                              qtyPrescribed > 0
                            ) {
                              setRefillsHistory([
                                {
                                  Date: firstFillDate,
                                  DrugId: item.id,
                                  DrugName: item.label,
                                  Qty: qtyPrescribed,
                                  editable: false,
                                  isFirstRefill: true,
                                },
                                ...refillsHistory.filter(
                                  (refillHistory) =>
                                    refillHistory.isFirstRefill !== true
                                ),
                              ]);
                            }
                          }}
                          loadOptions={(inputValue, callback) =>
                            loadOptions("drugs", inputValue, callback)
                          }
                          formatOptionLabel={formatOptionLabel}
                        />

                        <small className="text-danger">
                          {formErrors.prescribedDrug}
                        </small>
                      </>
                    )}
                  </div>
                </div>

                <div className={getCustomClasses("pharmacyBranch")}>
                  <label htmlFor="pharmacy-ddl" className="form-label">
                    Pharmacy
                  </label>
                  <i className="fa fa-asterisk"></i>

                  {isDemo ? <div>ABCD Pharmacy (1 Main st, Texas, Texas)</div> : <>
                    {props?.viewMode ? (
                      <div>
                        {props?.request?.fromPharmacyData?.Name} -{" "}
                        {props?.request?.fromPharmacyData?.Address}
                      </div>
                    ) : (
                      <>
                        <AsyncSelect
                          test-id="pharmacy-ddl"
                          id="pharmacy-ddl"
                          className="me-3 w-100"
                          classNamePrefix="react-select"
                          menuPosition="fixed"
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          value={pharmacyBranch}
                          loadOptions={(inputValue, callback) =>
                            loadOptions("pharmacies", inputValue, callback)
                          }
                          formatOptionLabel={formatOptionLabel}
                          onChange={(item) => {
                            setPharmacyBranch(item);
                          }}
                        />
                        <small className="text-danger">
                          {formErrors.pharmacyBranch}
                        </small>
                      </>
                    )}</>}
                </div>

                <div className={getCustomClasses("prescriber")}>
                  <label htmlFor="prescriber-ddl" className="form-label">
                    Prescriber
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <div className="d-flex">
                    <AsyncSelect
                      id="prescriber-ddl"
                      test-id="prescriber-ddl"
                      className="me-3 w-100"
                      classNamePrefix="react-select"
                      menuPosition="fixed"
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      value={prescriber}
                      loadOptions={(inputValue, callback) =>
                        loadOptions("prescribers", inputValue, callback)
                      }
                      components={{ Menu: PrescriberMenu, SingleValue }}
                      formatOptionLabel={(dataRow, { inputValue }) => {
                        return (
                          <div className="row">
                            <div className="col">{dataRow.label}</div>
                            <div className="col">{dataRow.npi}</div>
                            <div className="col">
                              {(dataRow.address &&
                                helpers.getPharmacyFormattedAddress(
                                  dataRow.state,
                                  dataRow.city,
                                  dataRow.address
                                )) ||
                                ""}
                            </div>
                          </div>
                        );
                      }}
                      onChange={(item) => {
                        setPrescriber(item);
                        console.log("item", item);
                        if (item?.PrescriberSupervisorId) {
                          setPrescriberSupervisor({
                            id: item.PrescriberSupervisorId,
                            label: item.PrescriberSupervisorName,
                          });
                        }
                      }}
                    />
                    <button
                      className="btn btn-outline-primary rounded-circle"
                      type="button"
                      title="Add New Prescriber"
                      onClick={() => setPrescriberModalShow(1)}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                  {formErrors.prescriber && (
                    <small className="text-danger">
                      {formErrors.prescriber}
                    </small>
                  )}
                </div>

                <div className={getCustomClasses("rxIssueDate")}>
                  <label htmlFor="issue-date-input" className="form-label">
                    Date Written
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <input
                    id="issue-date-input"
                    test-id="issue-date-input"
                    value={rxIssueDate}
                    onChange={(event) => {
                      setRxIssueDate(event.target.value);

                      const item = prescribedDrug || dispensedDrug;
                      const rxIssueDateValue = moment(event.target.value);
                      if (!!item && rxIssueDateValue) {
                        setExpirationDate(
                          rxIssueDateValue
                            .add(
                              item?.controlledSubstanceCode !== undefined &&
                                item?.controlledSubstanceCode !== ""
                                ? 179
                                : 364,
                              "days"
                            )
                            .format("yyyy-MM-DD")
                        );
                      }
                    }}
                    type="date"
                    max={moment().format("yyyy-MM-DD")}
                    min={moment().subtract(1, "years").format("yyyy-MM-DD")}
                    className="form-control"
                  />
                  <div className="invalid-feedback">
                    {formErrors.rxIssueDate}
                  </div>
                </div>
                <div className={getCustomClasses("prescriberSupervisor")}>
                  <label
                    htmlFor="prescriber-supervisor-ddl"
                    className="form-label"
                  >
                    Prescriber Supervisor
                  </label>
                  <div className="d-flex">
                    <AsyncSelect
                      id="prescriber-supervisor-ddl"
                      test-id="prescriber-supervisor-ddl"
                      className="me-3 w-100"
                      classNamePrefix="react-select"
                      menuPosition="fixed"
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      value={prescriberSupervisor}
                      components={{ Menu: PrescriberMenu, SingleValue }}
                      formatOptionLabel={(dataRow, { inputValue }) => {
                        return (
                          <div className="row">
                            <div className="col">{dataRow.label}</div>
                            <div className="col">{dataRow.npi}</div>
                            <div className="col">
                              {(dataRow.address &&
                                helpers.getPharmacyFormattedAddress(
                                  dataRow.state,
                                  dataRow.city,
                                  dataRow.address
                                )) ||
                                ""}
                            </div>
                          </div>
                        );
                      }}
                      loadOptions={(inputValue, callback) =>
                        loadOptions("prescribers", inputValue, callback)
                      }
                      onChange={(item) => {
                        console.log("item", item);
                        setPrescriberSupervisor(item);
                      }}
                    />
                    <button
                      className="btn btn-outline-primary rounded-circle"
                      type="button"
                      title="Add New Prescriber"
                      onClick={() => setPrescriberModalShow(2)}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                  <small className="text-danger">
                    {formErrors.prescriberSupervisor}
                  </small>
                </div>

                <div className={getCustomClasses("dispenseAsWrittenType")}>
                  <label
                    htmlFor="dispenseAsWrittenType-ddl"
                    className="form-label"
                  >
                    Dispense As Written (DAW)
                  </label>
                  {fieldsSchema["dispenseAsWrittenType"].isRequired ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <Select
                    id="dispenseAsWrittenType-ddl"
                    test-id="dispenseAsWrittenType-ddl"
                    name="dispenseAsWrittenType"
                    classNamePrefix="react-select"
                    getOptionValue={(option) => option.id}
                    isClearable={true}
                    menuPlacement={"auto"}
                    options={lookups?.dispenseAsWrittenTypes}
                    value={dispenseAsWrittenType}
                    onChange={(item) => {
                      setDispenseAsWrittenType(item)
                      console.log("item", item)
                    }}
                    formatOptionLabel={formatOptionLabel}
                  />
                </div>

                <div className={`d-none`}>
                  <label htmlFor="dispensed-drug-ddl" className="form-label">
                    Dispensed Drug
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <AsyncCreatableSelect
                    test-id="dispensed-drug-ddl"
                    id="dispensed-drug-ddl"
                    className="me-3 w-100"
                    classNamePrefix="react-select"
                    menuPosition="fixed"
                    getOptionValue={(option) => option.id}
                    menuPlacement={"auto"}
                    isClearable={true}
                    value={dispensedDrug}
                    onCreateOption={(inputValue) => {
                      setDispensedDrug({
                        id: null,
                        label: inputValue,
                      });
                    }}
                    isDisabled={prescribedDrug?.label && !prescribedDrug?.id}
                    onChange={(item) => {
                      setDispensedDrug(item);

                      if (!!rxIssueDate) {
                        const rxIssueDateValue = moment(rxIssueDate);
                        setExpirationDate(
                          rxIssueDateValue
                            .add(
                              item?.controlledSubstanceCode !== undefined &&
                                item?.controlledSubstanceCode !== ""
                                ? 179
                                : 364,
                              "days"
                            )
                            .format("yyyy-MM-DD")
                        );
                      }
                    }}
                    loadOptions={(inputValue, callback) =>
                      loadOptions("drugs", inputValue, callback)
                    }
                    formatOptionLabel={formatOptionLabel}
                  />
                  {formErrors.dispensedDrug && (
                    <small className="text-danger">
                      {formErrors.dispensedDrug}
                    </small>
                  )}
                </div>

                <div className={getCustomClasses("expirationDate")}>
                  <label htmlFor="expiration-date-input" className="form-label">
                    Expiration Date
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <input
                    id="expiration-date-input"
                    test-id="expiration-date-input"
                    value={expirationDate}
                    min={moment().format("yyyy-MM-DD")}
                    onChange={(event) => setExpirationDate(event.target.value)}
                    type="date"
                    className="form-control"
                  />

                  <small className="text-danger">
                    {formErrors.expirationDate}
                  </small>
                </div>

                <div className={getCustomClasses("qtyPrescribed")}>
                  <label htmlFor="qty-prescribed-input" className="form-label">
                    Qty Prescribed
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <input
                    id="qty-prescribed-input"
                    test-id="qty-prescribed-input"
                    type="number"
                    min="1"
                    className="form-control"
                    value={qtyPrescribed}
                    onChange={(event) => {
                      setQtyPrescribed(event?.target.valueAsNumber);
                      if (
                        refillsRemaining !== undefined &&
                        refillsRemaining !== 0
                      ) {
                        let extra = neverFilled
                          ? event?.target.valueAsNumber
                          : 0;
                        setQtyRemaining(
                          event?.target.valueAsNumber * refillsRemaining + extra
                        );
                      }

                      if (
                        !!firstFillDate &&
                        prescribedDrug !== null &&
                        event?.target.valueAsNumber > 0
                      ) {
                        setRefillsHistory([
                          {
                            Date: firstFillDate,
                            DrugId: prescribedDrug.id,
                            DrugName: prescribedDrug.label,
                            Qty: event?.target.valueAsNumber,
                            editable: false,
                            isFirstRefill: true,
                          },
                          ...refillsHistory.filter(
                            (refillHistory) =>
                              refillHistory.isFirstRefill !== true
                          ),
                        ]);
                      }
                    }}
                  />
                  {formErrors.qtyPrescribed && (
                    <small className="text-danger">
                      {formErrors.qtyPrescribed}
                    </small>
                  )}
                </div>

                <div className={getCustomClasses("originalRefills")}>
                  <label
                    htmlFor="original-refills-input"
                    className="form-label"
                  >
                    Original Refills
                  </label>
                  {props.requesttype === 1 ? (
                    <span className="form-field-optional-mark">(optional)</span>
                  ) : null}
                  <input
                    id="original-refills-input"
                    test-id="original-refills-input"
                    type="number"
                    min="1"
                    className="form-control"
                    value={originalRefills}
                    onChange={(event) =>
                      setOriginalRefills(event?.target.valueAsNumber)
                    }
                  />
                </div>

                <div className={getCustomClasses("refillsRemaining")}>
                  <label
                    htmlFor="refills-remaining-input"
                    className="form-label"
                  >
                    Refills Remaining
                  </label>
                  {props.requesttype === 1 ? (
                    <span className="form-field-optional-mark">(optional)</span>
                  ) : null}
                  <input
                    id="refills-remaining-input"
                    test-id="refills-remaining-input"
                    type="number"
                    min="1"
                    className="form-control"
                    value={refillsRemaining}
                    onChange={(event) => {
                      setRefillsRemaining(event?.target.valueAsNumber);
                      if (qtyPrescribed !== undefined && qtyPrescribed !== 0) {
                        let extra = neverFilled ? qtyPrescribed : 0;
                        setQtyRemaining(
                          event?.target.valueAsNumber * qtyPrescribed + extra
                        );
                      }
                    }}
                  />
                  <small className="text-danger">
                    {formErrors.refillsRemaining}
                  </small>
                </div>
                <div className={getCustomClasses("qtyRemaining")}>
                  <label htmlFor="qty-remaining-input" className="form-label">
                    Qty Remaining
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <input
                    id="qty-remaining-input"
                    test-id="qty-remaining-input"
                    type="number"
                    min="1"
                    className="form-control"
                    value={qtyRemaining}
                    onChange={(event) =>
                      setQtyRemaining(event?.target.valueAsNumber)
                    }
                  />
                  <input
                    id="manual-qty-remaining-cb"
                    test-id="never-filled-cb"
                    type="checkbox"
                    className="form-check-input"
                    checked={isManualQtyRemaining}
                    onChange={(event) => {

                      if (event.target.checked) {
                        setIsManualQtyRemaining(true);
                      } else {
                        setIsManualQtyRemaining(false);
                      }

                    }}
                  />
                  <label htmlFor="manual-qty-remaining-cb" className="form-label">
                    Manual Qty Remaining
                  </label>
                </div>

                <div className={getCustomClasses("uploadImg")}>
                  <label htmlFor="upload-img-input" className="form-label">
                    Upload Image
                  </label>
                  {props.requesttype === 1 ? (
                    <span className="form-field-optional-mark">(optional)</span>
                  ) : null}
                  <input
                    id="upload-img-input"
                    test-id="upload-img-input"
                    type="file"
                    className="form-control"
                    // value={qtyRemaining}
                    onChange={uploadHandler}
                  />
                </div>

                <div className={getCustomClasses("notes")}>
                  <label htmlFor="address-input" className="form-label">
                    Notes
                  </label>
                  {props.requesttype === 1 ? (
                    <span className="form-field-optional-mark">(optional)</span>
                  ) : null}
                  <textarea
                    id="notes-input"
                    test-id="notes-input"
                    name="notes"
                    className="form-control"
                    value={notes}
                    onChange={(event) => setNotes(event?.target.value)}
                  ></textarea>

                  <small className="text-danger">{formErrors.notes}</small>
                </div>

                <div className={getCustomClasses("directions")}>
                  <label htmlFor="address-input" className="form-label">
                    Directions
                  </label>
                  {props.requesttype === 1 ? (
                    <i className="fa fa-asterisk"></i>
                  ) : null}
                  <textarea
                    id="directions-input"
                    test-id="directions-input"
                    name="directions"
                    className="form-control"
                    value={directions}
                    onChange={(event) => setDirections(event?.target.value)}
                  ></textarea>

                  <small className="text-danger">{formErrors.directions}</small>
                </div>

                <div className={getCustomClasses("neverFilled")}>
                  <Card>
                    <Card.Header>
                      <div
                        className="form-check"
                        id="never-filled-checkbox"
                        test-id="never-filled-checkbox"
                      >
                        <input
                          id="never-filled-cb"
                          test-id="never-filled-cb"
                          type="checkbox"
                          className="form-check-input"
                          disabled={
                            csCheckpointResult?.answer1 === true ||
                            !!props.request?.ControlledSubstanceCode
                          }
                          checked={neverFilled === 1}
                          onChange={(event) => {
                            setNeverFilled(
                              event.target.checked === true ? 1 : 0
                            );

                            if (!isManualQtyRemaining) {
                              if (event.target.checked) {
                                setQtyRemaining(qtyRemaining + qtyPrescribed);
                              } else {
                                setQtyRemaining(qtyRemaining - qtyPrescribed);
                              }
                            }

                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="never-filled-cb"
                        >
                          Never Filled
                        </label>
                      </div>
                    </Card.Header>

                    <Card.Body className={`${neverFilled ? "d-none" : ""}`}>
                      <div className={getCustomClasses("firstFillDate")}>
                        <label
                          htmlFor="first-fill-date-input"
                          className="form-label"
                        >
                          First Fill Date
                        </label>
                        {props.requesttype === 1 ? (
                          <i className="fa fa-asterisk"></i>
                        ) : null}
                        <input
                          id="first-fill-date-input"
                          test-id="first-fill-date-input"
                          type="date"
                          value={firstFillDate}
                          max={moment().format("yyyy-MM-DD")}
                          min={rxIssueDate || moment().format("yyyy-MM-DD")}
                          className="form-control"
                          onChange={(event) => {
                            setFirstFillDate(event.target.value);
                            if (
                              !!event.target.value &&
                              prescribedDrug !== null &&
                              qtyPrescribed > 0
                            ) {
                              setRefillsHistory([
                                {
                                  Date: event.target.value,
                                  DrugId: prescribedDrug.id,
                                  DrugName: prescribedDrug.label,
                                  Qty: qtyPrescribed,
                                  editable: false,
                                  isFirstRefill: true,
                                },
                                ...refillsHistory.filter(
                                  (refillHistory) =>
                                    refillHistory.isFirstRefill !== true
                                ),
                              ]);
                            }
                          }}
                        />

                        <small className="text-danger">
                          {formErrors.firstFillDate}
                        </small>
                      </div>

                      <div className={getCustomClasses("firstFillDate")}>
                        <label
                          htmlFor="last-fill-date-input"
                          className="form-label"
                        >
                          Last Fill Date
                        </label>
                        {props.requesttype === 1 ? (
                          <i className="fa fa-asterisk"></i>
                        ) : null}
                        <input
                          id="last-fill-date-input"
                          test-id="last-fill-date-input"
                          type="date"
                          value={lastFillDate}
                          max={moment().format("yyyy-MM-DD")}
                          min={rxIssueDate || moment().format("yyyy-MM-DD")}
                          className="form-control"
                          onChange={(event) =>
                            setLastFillDate(event.target.value)
                          }
                        />
                        <small className="text-danger">
                          {formErrors.lastFillDate}
                        </small>
                      </div>

                      <div className={getCustomClasses("refillsHistory")}>
                        <label className="form-label">Refills History</label>
                        {props.requesttype === 1 ? (
                          <span className="form-field-optional-mark">
                            (optional)
                          </span>
                        ) : null}
                        <DrugRefillsHistoryDataGrid
                          refills={refillsHistory}
                          firstFill={firstFillDate}
                          setDrugRefillsHistory={(refills) =>
                            setRefillsHistory(refills)
                          }
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          {props?.request?.RxNumber === "ALL ACTIVE RXs" && (
            <>
              <button
                className="btn btn-default"
                onClick={() => {
                  setPrescriptions([
                    ...prescriptions,
                    {
                      index: prescriptions.length + 1,
                      neverFilled: 1,
                      qtyPrescribed: 0,
                      originalRefills: 0,
                      qtyRemaining: 0,
                      refillsRemaining: 0,
                      refillsHistory: [],
                    },
                  ]);
                }}
              >
                + Add new RX (Total: {prescriptions.length})
              </button>
            </>
          )}
          <button
            className="btn btn-default"
            test-id="transfer-in-out-submit-btn"
            onClick={async () => {
              if (!isUploading) {
                if (props.requesttype === 1 && props?.request?.StatusId === 2) {
                  const result = await Swal.fire({
                    title: "",
                    text: `Are you sure you want to save changes and submit request #${props?.request?.RequestId} for acceptance ?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#6c757d",
                    confirmButtonText: "Yes",
                  });
                  if (result.isConfirmed) {
                    handleSubmit(1);
                  }
                } else {
                  handleSubmit();
                }
              }
            }}
          >
            {isUploading
              ? "Uploading...."
              : props?.request?.RxNumber === "ALL ACTIVE RXs"
                ? "Submit all RXs"
                : firstDose.inFirstDose(
                  props?.request?.FromPharmacyId,
                  firstDose.pharmacies
                )
                  ? "Prescription is ready"
                  : "Submit"}
          </button>

          {queryResult?.length > 0 && <div>
            <label htmlFor="queryResult" className="form-label" style={{
              marginTop: "10px",
              color: queryResult === "InStock" ? "green" :
                queryResult.includes("Insufficient Quantity:") ? "orange" : "red"
            }}>
              Product locator query result : {queryResult}
            </label>
          </div>}
          {props.showRejectBtn && (
            <button
              className="btn btn-danger"
              onClick={() => props.handleRejectTransferRequestModalShow(true)}
            >
              Reject
            </button>
          )}

          {props.requesttype === 0 && !props?.viewMode ? (
            <button
              className="btn btn-outline-primary rounded-circle btn-show-more"
              type="button"
              title={`Show ${showMoreFields ? "less" : "more"} fields`}
              onClick={() => toggleShowMoreFields()}
            >
              <i
                className={`fa-solid fa-chevron-${showMoreFields ? "up" : "down"
                  }`}
              ></i>
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>

      <EditPatientModal
        show={patientModalShow}
        onHide={(patient) => {
          if (patient !== undefined) {
            setPatient({
              id: patient?.Id,
              label: patient
                ? `${patient?.FirstName} ${patient?.LastName}`
                : "",
            });
          }
          setPatientModalShow(false);
        }}
      ></EditPatientModal>

      <PrescriberModal
        show={prescriberModalShow}
        onHide={(prescriber) => {
          if (prescriber !== undefined) {
            (prescriberModalShow === 1
              ? setPrescriber
              : setPrescriberSupervisor)({
                id: prescriber?.Id,
                label: prescriber
                  ? `${prescriber?.FirstName} ${prescriber?.LastName}`
                  : "",
              });

            if (prescriber?.PrescriberSupervisorId) {
              setPrescriberSupervisor({
                id: prescriber?.PrescriberSupervisorId,
                label: prescriber.PrescriberSupervisorName,
              });
            }
          }
          setPrescriberModalShow(0);
        }}
      ></PrescriberModal>

      <CsCheckpointModal
        show={csCheckpointModalShow}
        onHide={(canProceed, { answer1, answer2 }) => {
          if (canProceed === false) {
            setPrescribedDrug(null);
            setDispensedDrug(null);
            Emitter.emit("SHOW_NOTIFICATION", {
              message: "Unable to proceed with the selected prescribed drug!",
              isError: true,
            });
          } else {
            setNeverFilled(0);
          }
          setCsCheckpointResult({ answer1, answer2 });
          setCsCheckpointModalShow(false);
        }}
      ></CsCheckpointModal>
    </>
  );
};

export default TransferRequestModal;
